import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { ContactForm } from '../forms'
import Testimonials from '../Testimonials'
import redditimg from '../../assets/img/redditimg.png'
import { Link } from 'gatsby'


const ContactPageTemplate = ({ title, subtitle, meta_title, meta_description, testimonials }) => {
  return <div>
    <Helmet>
      <title>{meta_title}</title>
      <meta name='description' content={meta_description} />
    </Helmet>
    <section className='hero is-medium'>
      <div className='hero-body pb-0'>
        <div className='column is-three-fifths is-offset-one-fifth'>
          <h1 className='title is-size-1 has-text-weight-bold'>
            Hi
          </h1>
        </div>
      </div>
    </section>

    <section className='section column is-three-fifths is-offset-one-fifth'>

      <div className='columns is-variable is-8 my-4'> 
        <div className='column content'>
          <h4 className='has-text-weight-bold is-size-4'>Questions about BoldRewrite.com?</h4>
          <p>For general questions, technical feedback or business enquiries then please use the form to the right.</p>
          <p>We also keep our FAQs up to date, so your answer may be <Link to='/faq'>just around the corner</Link>.</p>
          <h4 className='has-text-weight-bold is-size-4'>Questions about you?</h4>
          <p>The first step is a rewrite. It will kickstart our relationship, fire us all up, and change your businesses.</p>
          <p>It's the only way to receive specific insights + feedback on your challenges. Don't worry – after that, you'll get the direct dial.</p>
        </div>


        <div className='column'>
          <ContactForm />
        </div>
      </div>

    </section>

  </div>
}

ContactPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  testimonials: PropTypes.array,
}

export default ContactPageTemplate
